import Layout from "../components/layout/layout";
import * as React from "react";

const Page404 = () => {
    return <Layout>
        <p>
            du bist hier falsch! <a href={'/'}>zur Startseite</a>
        </p>
    </Layout>
}

export default Page404
